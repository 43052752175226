import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

// *** componentes ***  //
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Redes from '../Components/Redes';



// *** vistas ***  //
import Index from '../Views/Index';
import Nosotros from '../Views/Nosotros';
import Productos from '../Views/Productos';
import Producto from '../Views/Producto';
import Contacto from '../Views/Contacto';
import Mensaje from '../Views/Mensaje';
import AvisoPrivacidad from '../Views/AvisoPrivacidad';
import Terminos from '../Views/Terminos';

import Error from '../Views/Error';

const Routers=() => (
    <BrowserRouter>
        <Header />
        <Redes />

        <Routes>
            {/* vistas */}
            <Route exact path="/" element={<Index />} />
            <Route path="/nosotros" element={<Nosotros />} />
            <Route path="/productos" element={<Productos />} />
            <Route path="/producto/:id" element={<Producto />} />
            <Route path="/contactanos" element={<Contacto />} />
            <Route path="/envio-exitoso" element={<Mensaje />} />
            <Route path="/aviso-de-privacidad" element={<AvisoPrivacidad />} />
            <Route path="/terminos-y-condiciones" element={<Terminos />} />
            <Route path="/*" element={<Error />} />
        </Routes>

        <Footer />
    </BrowserRouter>
);

export default Routers;