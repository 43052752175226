import React from "react";
import { Link } from "react-router-dom";


const Mensaje = () => {
    return (
        <section style={{display:"flex", justifyContent:"center", alignItems:"center", flexWrap:"wrap", minHeight:"50vh", maxHeight:"60vh"}} className="container row">
            <div className="col s12 m6 order-2 center-movil-text">
                <p className="titulo-1">¡Formulario enviado!</p>
                <p>
                    Gracias por enviarnos tu mensaje, <br />
                    en breve nos pondremos en contacto contigo.
                </p>
                <div style={{margin:"30px auto"}}>
                    <Link className='boton-1' to={"/"}>Volver al Inicio</Link>
                </div>
            </div>
            <div className="col s12 m6 order-1">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={{width:"100%", height:"100%"}}
                    viewBox="0 0 535.231 494.204"
                >
                    <g id="Elements" transform="translate(-535.24 -171.265)">
                        <g id="Leaf" transform="translate(577.055 434.386)">
                            <path
                                id="Trazado_7"
                                data-name="Trazado 7"
                                d="M643.386,436.455c-7.255,4.564-3.335,25.564,6.292,39.828l-17.854-16.689a50.416,50.416,0,0,1-1.848-18.581c.915-13.391-.489-27.82-7.758-23.246-6.811,4.285-3.772,23.056,4.591,37.14l-22.927-21.431a33.23,33.23,0,0,1-.791-10.389c.576-8.441-.309-17.532-4.89-14.651-3.974,2.5-2.627,12.831,1.78,21.394l-7.43-6.946a48.3,48.3,0,0,1-6.632-10.822c-4.483-10.795-10.717-20.189-14.464-14.985s7.281,21.172,20.292,28.568l3.823,3.575c-9.388-5.557-22.957-7.2-25.54-2.4-2.566,4.764,6.566,5.035,14.947,3.89a30.2,30.2,0,0,1,12.931.7l18.061,16.882c-14.891-8.756-36.311-11.321-40.4-3.735-4.072,7.559,10.417,7.989,23.717,6.174,10.05-1.372,16.971.015,20.445,1.078l21.659,20.246c-14.843-8.357-36.119-10.97-39.541-3.24-2.638,5.959,6.6,7.786,21.61,5.808a55.216,55.216,0,0,1,22.192,1.416l10.2,9.536a4.326,4.326,0,0,0,1.752-1.875l-4.06-3.794c-1.293-3.436-3.088-10.2-2.405-20.2C652.058,446.31,650.653,431.883,643.386,436.455Z"
                                transform="translate(-569.591 -395.601)"
                                fill="#73dc74"
                            />
                            <path
                                id="Trazado_8"
                                data-name="Trazado 8"
                                d="M950.141,496.862c-4.565-7.255-25.565-3.334-39.829,6.292L927,485.3a50.4,50.4,0,0,1,18.58-1.847c13.393.914,27.82-.491,23.248-7.759-4.286-6.811-23.058-3.771-37.141,4.592l21.431-22.927a33.273,33.273,0,0,1,10.391-.791c8.439.577,17.532-.309,14.651-4.889-2.5-3.976-12.831-2.628-21.395,1.78l6.946-7.431a48.308,48.308,0,0,1,10.823-6.632c10.794-4.483,20.188-10.716,14.983-14.464s-21.171,7.282-28.568,20.292l-3.574,3.825c5.556-9.388,7.194-22.958,2.4-25.54-4.764-2.567-5.036,6.565-3.892,14.947a30.225,30.225,0,0,1-.694,12.93l-16.882,18.061c8.756-14.891,11.319-36.311,3.733-40.4-7.559-4.073-7.989,10.416-6.174,23.716,1.372,10.051-.013,16.971-1.078,20.445l-20.245,21.659c8.355-14.843,10.969-36.119,3.24-39.541-5.96-2.637-7.787,6.6-5.808,21.61a55.208,55.208,0,0,1-1.417,22.192l-9.536,10.2a4.32,4.32,0,0,0,1.875,1.752l3.794-4.058c3.436-1.294,10.2-3.089,20.2-2.407C940.286,505.535,954.713,504.13,950.141,496.862Z"
                                transform="translate(-497.579 -389.633)"
                                fill="#73dc74"
                            />
                        </g>
                        <g id="Sending" transform="translate(881.489 171.265)">
                            <g id="Grupo_24" data-name="Grupo 24">
                                <g
                                    id="Grupo_21"
                                    data-name="Grupo 21"
                                    transform="translate(0 28.094)"
                                    opacity="0.3"
                                >
                                    <g id="Grupo_20" data-name="Grupo 20">
                                        <g
                                            id="Grupo_18"
                                            data-name="Grupo 18"
                                            transform="translate(103.051)"
                                        >
                                            <path
                                                id="Trazado_9"
                                                data-name="Trazado 9"
                                                d="M879.145,236.586a1.513,1.513,0,0,0,.717,0c2.585-.636,4.08-.893,4.1-.9a1.362,1.362,0,0,0,1.16-1.571,1.438,1.438,0,0,0-1.674-1.089c-.063.011-1.606.275-4.311.941a1.345,1.345,0,0,0-1.029,1.649A1.4,1.4,0,0,0,879.145,236.586Z"
                                                transform="translate(-878.056 -233.007)"
                                                fill="#f74a4a"
                                            />
                                        </g>
                                        <g
                                            id="Grupo_19"
                                            data-name="Grupo 19"
                                            transform="translate(0 3.247)"
                                        >
                                            <path
                                                id="Trazado_10"
                                                data-name="Trazado 10"
                                                d="M834.158,270.982c-1.34,2.3-2.632,4.677-3.838,7.076a1.474,1.474,0,0,1-1.654.733,1.444,1.444,0,0,1-.263-.088,1.316,1.316,0,0,1-.687-1.8c1.231-2.449,2.549-4.876,3.918-7.221a1.489,1.489,0,0,1,1.954-.535A1.309,1.309,0,0,1,834.158,270.982Z"
                                                transform="translate(-813.646 -208.716)"
                                                fill="#f74a4a"
                                            />
                                            <path
                                                id="Trazado_11"
                                                data-name="Trazado 11"
                                                d="M829.7,279.051c-1.038,2.424-2.025,4.933-2.932,7.452a1.455,1.455,0,0,1-1.716.877c-.037-.009-.072-.018-.109-.03a1.333,1.333,0,0,1-.9-1.713c.925-2.569,1.931-5.123,2.989-7.6a1.474,1.474,0,0,1,1.873-.75A1.325,1.325,0,0,1,829.7,279.051Z"
                                                transform="translate(-816.411 -202.429)"
                                                fill="#f74a4a"
                                            />
                                            <path
                                                id="Trazado_12"
                                                data-name="Trazado 12"
                                                d="M820.77,305.261a1.356,1.356,0,0,1-1.072-1.481c.369-2.691.8-5.379,1.289-8a1.435,1.435,0,0,1,1.667-1.1,1.356,1.356,0,0,1,1.171,1.562c-.48,2.578-.908,5.226-1.27,7.876a1.462,1.462,0,0,1-1.785,1.137Z"
                                                transform="translate(-819.686 -189.055)"
                                                fill="#f74a4a"
                                            />
                                            <path
                                                id="Trazado_13"
                                                data-name="Trazado 13"
                                                d="M825.271,285.847a1.344,1.344,0,0,1,1,1.667c-.353,1.186-.694,2.387-1.011,3.57-.369,1.363-.724,2.759-1.056,4.145a1.444,1.444,0,0,1-1.721,1.022l-.037-.009a1.35,1.35,0,0,1-1.05-1.607c.337-1.411.7-2.828,1.073-4.216.323-1.2.671-2.426,1.029-3.633a1.452,1.452,0,0,1,1.741-.946Z"
                                                transform="translate(-818.406 -195.843)"
                                                fill="#f74a4a"
                                            />
                                            <path
                                                id="Trazado_14"
                                                data-name="Trazado 14"
                                                d="M834.889,270.021a1.483,1.483,0,0,1-1.57.588,1.51,1.51,0,0,1-.417-.168,1.3,1.3,0,0,1-.447-1.864c1.534-2.279,3.164-4.539,4.843-6.714a1.5,1.5,0,0,1,2.011-.3,1.3,1.3,0,0,1,.321,1.887C837.986,265.579,836.391,267.79,834.889,270.021Z"
                                                transform="translate(-810.083 -214.592)"
                                                fill="#f74a4a"
                                            />
                                            <path
                                                id="Trazado_15"
                                                data-name="Trazado 15"
                                                d="M878.8,237.477c-2.627.844-5.293,1.787-7.923,2.807a1.52,1.52,0,0,1-.9.062,1.42,1.42,0,0,1-.98-.8,1.324,1.324,0,0,1,.782-1.764c2.685-1.042,5.407-2.006,8.091-2.867a1.466,1.466,0,0,1,1.827.842A1.335,1.335,0,0,1,878.8,237.477Z"
                                                transform="translate(-782.023 -234.846)"
                                                fill="#f74a4a"
                                            />
                                            <path
                                                id="Trazado_16"
                                                data-name="Trazado 16"
                                                d="M862.31,244.55a1.525,1.525,0,0,1-1.056.131,1.426,1.426,0,0,1-.9-.651,1.309,1.309,0,0,1,.556-1.84c2.5-1.312,5.083-2.571,7.662-3.743a1.482,1.482,0,0,1,1.923.629,1.316,1.316,0,0,1-.669,1.8C867.292,242.029,864.764,243.263,862.31,244.55Z"
                                                transform="translate(-788.698 -232.193)"
                                                fill="#f74a4a"
                                            />
                                            <path
                                                id="Trazado_17"
                                                data-name="Trazado 17"
                                                d="M846.967,255.729a1.5,1.5,0,0,1-1.34.325,1.462,1.462,0,0,1-.7-.387,1.293,1.293,0,0,1,.067-1.91c2.089-1.836,4.271-3.639,6.486-5.356a1.508,1.508,0,0,1,2.029.182,1.3,1.3,0,0,1-.194,1.9C851.15,252.166,849.013,253.931,846.967,255.729Z"
                                                transform="translate(-800.659 -224.707)"
                                                fill="#f74a4a"
                                            />
                                            <path
                                                id="Trazado_18"
                                                data-name="Trazado 18"
                                                d="M854.293,249.712a1.513,1.513,0,0,1-1.2.217,1.434,1.434,0,0,1-.81-.516,1.3,1.3,0,0,1,.318-1.887c2.309-1.578,4.71-3.114,7.138-4.569a1.5,1.5,0,0,1,1.988.411,1.3,1.3,0,0,1-.438,1.866C858.91,246.66,856.557,248.165,854.293,249.712Z"
                                                transform="translate(-794.945 -228.8)"
                                                fill="#f74a4a"
                                            />
                                            <path
                                                id="Trazado_19"
                                                data-name="Trazado 19"
                                                d="M840.46,262.533a1.491,1.491,0,0,1-1.464.448,1.454,1.454,0,0,1-.563-.268,1.294,1.294,0,0,1-.191-1.9c1.827-2.074,3.75-4.119,5.715-6.08a1.507,1.507,0,0,1,2.034-.06,1.293,1.293,0,0,1,.063,1.91C844.132,258.5,842.25,260.5,840.46,262.533Z"
                                                transform="translate(-805.735 -219.951)"
                                                fill="#f74a4a"
                                            />
                                        </g>
                                    </g>
                                </g>
                                <g
                                    id="Grupo_23"
                                    data-name="Grupo 23"
                                    transform="translate(102.66)"
                                >
                                    <g id="Grupo_22" data-name="Grupo 22">
                                        <path
                                            id="Trazado_20"
                                            data-name="Trazado 20"
                                            d="M880.745,239.619l-2.91-22.525,76.316,10.945Z"
                                            transform="translate(-877.835 -217.094)"
                                            fill="#f74a4a"
                                        />
                                        <path
                                            id="Trazado_21"
                                            data-name="Trazado 21"
                                            d="M953.246,223.292l-70.5,17.318-.186.048-3.542,4.06.822-9.843Z"
                                            transform="translate(-876.929 -212.349)"
                                            fill="#d34040"
                                        />
                                        <path
                                            id="Trazado_22"
                                            data-name="Trazado 22"
                                            d="M879.018,237.188l6.336-.079-2.794-3.981Z"
                                            transform="translate(-876.929 -204.82)"
                                            fill="#84044b"
                                        />
                                        <path
                                            id="Trazado_23"
                                            data-name="Trazado 23"
                                            d="M889.895,238.477l-8.872,2.179L895.454,261.2l56.256-37.911Z"
                                            transform="translate(-875.395 -212.348)"
                                            fill="#f74a4a"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g id="Floral" transform="translate(535.24 285.308)">
                            <path
                                id="Trazado_24"
                                data-name="Trazado 24"
                                d="M548.345,336.135a1.3,1.3,0,1,1,2.609,0V342a1.3,1.3,0,0,1-2.609,0v-5.864Zm-8.884,4.761a1.3,1.3,0,0,1,1.844-1.845l4.145,4.147a1.3,1.3,0,1,1-1.842,1.844l-4.147-4.146Zm-2.918,9.649a1.3,1.3,0,0,1,0-2.609h5.865a1.3,1.3,0,0,1,0,2.609Zm4.762,8.884a1.3,1.3,0,0,1-1.844-1.842l4.147-4.147a1.3,1.3,0,1,1,1.842,1.845l-4.145,4.144Zm9.648,2.919a1.3,1.3,0,0,1-2.609,0v-5.865a1.3,1.3,0,0,1,2.609,0v5.865Zm8.886-4.761A1.3,1.3,0,1,1,558,359.429l-4.147-4.144a1.3,1.3,0,1,1,1.844-1.845l4.147,4.147Zm2.917-9.651a1.3,1.3,0,0,1,0,2.609h-5.865a1.3,1.3,0,0,1,0-2.609ZM558,339.05a1.3,1.3,0,0,1,1.844,1.845l-4.147,4.146a1.3,1.3,0,1,1-1.844-1.844Z"
                                transform="translate(-535.24 -259.727)"
                                fill="#f74a4a"
                                fillRule="evenodd"
                            />
                            <path
                                id="Trazado_25"
                                data-name="Trazado 25"
                                d="M574.706,507.283a1.3,1.3,0,1,1,2.609,0v5.864a1.3,1.3,0,1,1-2.609,0v-5.864Zm-8.884,4.761a1.3,1.3,0,0,1,1.843-1.845l4.146,4.147a1.3,1.3,0,0,1-1.843,1.844l-4.146-4.146Zm-2.919,9.649a1.3,1.3,0,0,1,0-2.609h5.865a1.3,1.3,0,0,1,0,2.609Zm4.762,8.884a1.3,1.3,0,0,1-1.843-1.842l4.146-4.147a1.3,1.3,0,0,1,1.843,1.845l-4.146,4.144Zm9.649,2.919a1.3,1.3,0,0,1-2.609,0v-5.865a1.3,1.3,0,0,1,2.609,0V533.5Zm8.886-4.761a1.3,1.3,0,0,1-1.845,1.842l-4.146-4.144a1.3,1.3,0,0,1,1.843-1.845l4.148,4.147Zm2.915-9.651a1.3,1.3,0,0,1,0,2.609h-5.864a1.3,1.3,0,0,1,0-2.609Zm-4.761-8.886a1.3,1.3,0,0,1,1.845,1.845l-4.148,4.146a1.3,1.3,0,0,1-1.843-1.844Z"
                                transform="translate(-529.513 -222.54)"
                                fill="#f74a4a"
                                fillRule="evenodd"
                            />
                            <path
                                id="Trazado_26"
                                data-name="Trazado 26"
                                d="M940.775,527.314a.814.814,0,0,1,1.629,0v3.659a.814.814,0,1,1-1.629,0v-3.659Zm-5.543,2.971a.814.814,0,0,1,1.15-1.152l2.587,2.588a.813.813,0,0,1-1.149,1.15l-2.588-2.587Zm-1.821,6.022a.814.814,0,0,1,0-1.629h3.659a.814.814,0,0,1,0,1.629Zm2.971,5.544a.813.813,0,0,1-1.15-1.149l2.588-2.588a.813.813,0,1,1,1.149,1.152l-2.587,2.586Zm6.022,1.822a.814.814,0,0,1-1.629,0v-3.66a.814.814,0,0,1,1.629,0v3.66Zm5.545-2.971a.813.813,0,1,1-1.15,1.149l-2.588-2.586a.814.814,0,0,1,1.15-1.152l2.588,2.588Zm1.821-6.023a.814.814,0,0,1,0,1.629h-3.66a.814.814,0,0,1,0-1.629Zm-2.971-5.545a.814.814,0,0,1,1.15,1.152l-2.588,2.587a.813.813,0,0,1-1.15-1.15Z"
                                transform="translate(-448.904 -218.081)"
                                fill="#f74a4a"
                                fillRule="evenodd"
                            />
                            <path
                                id="Trazado_27"
                                data-name="Trazado 27"
                                d="M620.418,369.948a.652.652,0,0,1,1.3,0v2.931a.652.652,0,0,1-1.3,0v-2.931Zm-4.442,2.38a.652.652,0,0,1,.922-.923l2.073,2.074a.652.652,0,1,1-.922.921l-2.073-2.073Zm-1.46,4.825a.652.652,0,0,1,0-1.3h2.932a.652.652,0,0,1,0,1.3ZM616.9,381.6a.651.651,0,1,1-.922-.92l2.073-2.074a.652.652,0,0,1,.922.923L616.9,381.6Zm4.825,1.461a.652.652,0,0,1-1.3,0v-2.934a.652.652,0,0,1,1.3,0v2.934Zm4.443-2.381a.652.652,0,1,1-.923.92l-2.073-2.072a.652.652,0,1,1,.922-.923l2.074,2.074Zm1.458-4.827a.652.652,0,0,1,0,1.3h-2.932a.652.652,0,0,1,0-1.3Zm-2.381-4.443a.652.652,0,0,1,.923.923l-2.074,2.073a.652.652,0,0,1-.922-.921Z"
                                transform="translate(-518.157 -252.238)"
                                fill="#f74a4a"
                                fillRule="evenodd"
                            />
                            <path
                                id="Trazado_28"
                                data-name="Trazado 28"
                                d="M901.858,274.131a1,1,0,1,1,2,0v4.492a1,1,0,0,1-2,0v-4.492Zm-6.807,3.647a1,1,0,0,1,1.413-1.413l3.176,3.177a1,1,0,0,1-1.412,1.413l-3.177-3.177Zm-2.236,7.394a1,1,0,0,1,0-2h4.494a1,1,0,0,1,0,2Zm3.649,6.806a1,1,0,0,1-1.413-1.411l3.177-3.177a1,1,0,0,1,1.412,1.413l-3.176,3.175Zm7.392,2.237a1,1,0,0,1-2,0v-4.494a1,1,0,0,1,2,0v4.494Zm6.808-3.648a1,1,0,0,1-1.413,1.411l-3.177-3.175a1,1,0,0,1,1.412-1.413l3.178,3.177Zm2.235-7.394a1,1,0,0,1,0,2h-4.494a1,1,0,0,1,0-2Zm-3.648-6.808a1,1,0,0,1,1.413,1.413l-3.178,3.177a1,1,0,0,1-1.412-1.413Z"
                                transform="translate(-457.765 -273.132)"
                                fill="#f74a4a"
                                fillRule="evenodd"
                                opacity="0.996"
                            />
                        </g>
                        <g id="Plus" transform="translate(548.182 386.781)">
                            <g
                                id="Grupo_39"
                                data-name="Grupo 39"
                                transform="translate(485.003)"
                            >
                                <g id="Grupo_38" data-name="Grupo 38">
                                    <g
                                        id="Grupo_34"
                                        data-name="Grupo 34"
                                        transform="translate(4.55)"
                                    >
                                        <g
                                            id="Grupo_32"
                                            data-name="Grupo 32"
                                            transform="translate(0 5.674)"
                                        >
                                            <path
                                                id="Trazado_32"
                                                data-name="Trazado 32"
                                                d="M948.778,361.154a.734.734,0,0,1,.734.734v3.243a.735.735,0,1,1-1.469,0v-3.243A.734.734,0,0,1,948.778,361.154Z"
                                                transform="translate(-948.043 -361.154)"
                                                fill="#f74a4a"
                                            />
                                        </g>
                                        <g id="Grupo_33" data-name="Grupo 33">
                                            <path
                                                id="Trazado_33"
                                                data-name="Trazado 33"
                                                d="M948.778,356.493a.734.734,0,0,1,.734.735v3.242a.735.735,0,1,1-1.469,0v-3.242A.735.735,0,0,1,948.778,356.493Z"
                                                transform="translate(-948.043 -356.493)"
                                                fill="#f74a4a"
                                            />
                                        </g>
                                    </g>
                                    <g
                                        id="Grupo_37"
                                        data-name="Grupo 37"
                                        transform="translate(0 4.458)"
                                    >
                                        <g id="Grupo_35" data-name="Grupo 35">
                                            <path
                                                id="Trazado_34"
                                                data-name="Trazado 34"
                                                d="M945.039,360.155h2.913a.735.735,0,0,1,0,1.47h-2.913a.735.735,0,0,1,0-1.47Z"
                                                transform="translate(-944.305 -360.155)"
                                                fill="#f74a4a"
                                            />
                                        </g>
                                        <g
                                            id="Grupo_36"
                                            data-name="Grupo 36"
                                            transform="translate(6.189)"
                                        >
                                            <path
                                                id="Trazado_35"
                                                data-name="Trazado 35"
                                                d="M950.123,360.155h2.913a.735.735,0,0,1,0,1.47h-2.913a.735.735,0,0,1,0-1.47Z"
                                                transform="translate(-949.389 -360.155)"
                                                fill="#f74a4a"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g
                                id="Grupo_47"
                                data-name="Grupo 47"
                                transform="translate(0 114.664)"
                            >
                                <g id="Grupo_46" data-name="Grupo 46">
                                    <g
                                        id="Grupo_42"
                                        data-name="Grupo 42"
                                        transform="translate(4.55)"
                                    >
                                        <g
                                            id="Grupo_40"
                                            data-name="Grupo 40"
                                            transform="translate(0 5.673)"
                                        >
                                            <path
                                                id="Trazado_36"
                                                data-name="Trazado 36"
                                                d="M550.345,455.35a.735.735,0,0,1,.735.735v3.242a.735.735,0,0,1-1.471,0v-3.242A.735.735,0,0,1,550.345,455.35Z"
                                                transform="translate(-549.61 -455.35)"
                                                fill="#f74a4a"
                                            />
                                        </g>
                                        <g id="Grupo_41" data-name="Grupo 41">
                                            <path
                                                id="Trazado_37"
                                                data-name="Trazado 37"
                                                d="M550.345,450.69a.735.735,0,0,1,.735.735v3.242a.735.735,0,0,1-1.471,0v-3.242A.735.735,0,0,1,550.345,450.69Z"
                                                transform="translate(-549.61 -450.69)"
                                                fill="#f74a4a"
                                            />
                                        </g>
                                    </g>
                                    <g
                                        id="Grupo_45"
                                        data-name="Grupo 45"
                                        transform="translate(0 4.456)"
                                    >
                                        <g id="Grupo_43" data-name="Grupo 43">
                                            <path
                                                id="Trazado_38"
                                                data-name="Trazado 38"
                                                d="M546.607,454.351h2.912a.735.735,0,1,1,0,1.47h-2.912a.735.735,0,1,1,0-1.47Z"
                                                transform="translate(-545.872 -454.351)"
                                                fill="#f74a4a"
                                            />
                                        </g>
                                        <g
                                            id="Grupo_44"
                                            data-name="Grupo 44"
                                            transform="translate(6.189)"
                                        >
                                            <path
                                                id="Trazado_39"
                                                data-name="Trazado 39"
                                                d="M551.691,454.351H554.6a.735.735,0,1,1,0,1.47h-2.912a.735.735,0,1,1,0-1.47Z"
                                                transform="translate(-550.956 -454.351)"
                                                fill="#f74a4a"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g id="Mail" transform="translate(598.481 230.905)">
                            <ellipse
                                id="Elipse_4"
                                data-name="Elipse 4"
                                cx="202.842"
                                cy="20.455"
                                rx="202.842"
                                ry="20.455"
                                transform="translate(0 393.654)"
                                fill="#ededed"
                                style={{ isolation: "isolate" }}
                            />
                            <circle
                                id="Elipse_5"
                                data-name="Elipse 5"
                                cx="0.251"
                                cy="0.251"
                                r="0.251"
                                transform="translate(379.69 88.599)"
                                fill="#e6e8ec"
                            />
                            <path
                                id="Trazado_40"
                                data-name="Trazado 40"
                                d="M823.131,232.4,640.688,350.763,759.75,565.5a8.306,8.306,0,0,0,5.3,4.046L872.93,595.862a8.318,8.318,0,0,0,9.446-4.472l53.51-111.171,58.645-78.395L855.363,237.178A24.662,24.662,0,0,0,823.131,232.4Z"
                                transform="translate(-575.57 -228.44)"
                                fill="#4e7cd6"
                            />
                            <path
                                id="Trazado_41"
                                data-name="Trazado 41"
                                d="M996.055,402.588l-.516.7-34.351,45.925-.622.83L937.411,481l-45.766,95.1-1.283,2.657-6.46,13.417a8.316,8.316,0,0,1-9.461,4.471L766.564,570.311a8.3,8.3,0,0,1-5.3-4.047l-10.374-18.675-1.864-3.363L674.146,409.118l-.643-1.152-31.3-56.44,3.781-2.455,28.667-18.593.663-.432,60.367-39.166,3.809-2.47,85.148-55.247a24.655,24.655,0,0,1,32.246,4.772l63.487,75.138,2.79,3.349,46.08,54.514.251.3,23.17,27.4,2.917,3.453Z"
                                transform="translate(-575.24 -228.276)"
                                fill="#4e7cd6"
                                opacity="0.1"
                                style={{ isolation: "isolate" }}
                            />
                            <path
                                id="Trazado_42"
                                data-name="Trazado 42"
                                d="M962.653,359.9l-5.623,39.068-1.7,11.791-.969,25.115-.078,1.961-5.043,131.228a8.326,8.326,0,0,1-10.123,7.793l-54.376-12.14-.585-.133L744.707,533.465l-2.561-.572-76.664-17.12,1.758-117.987.056-3.943.53-35.712.587-39.417,60.367-39.167,3.81-2.47c55.993,5.742,130.46,16.786,180.908,24.662l2.79,3.349L962.333,359.6Z"
                                transform="translate(-570.182 -217.872)"
                                opacity="0.1"
                                style={{ isolation: "isolate" }}
                            />
                            <path
                                id="Trazado_43"
                                data-name="Trazado 43"
                                d="M969.147,313.766l-6.621,46.046-6.069,42.165-1.7,11.763-.97,25.116-5.12,133.188a8.32,8.32,0,0,1-10.123,7.793l-54.961-12.271L744.134,536.443,664.909,518.75l1.814-121.928.53-35.712.621-41.776.635-42.969c13.1-.621,34.659.781,60.4,3.369,57.627,5.783,136.183,17.484,187.543,25.541C947.9,310.208,969.147,313.766,969.147,313.766Z"
                                transform="translate(-570.307 -218.059)"
                                fill="#dcdfed"
                            />
                            <rect
                                id="Rectángulo_8"
                                data-name="Rectángulo 8"
                                width="8.791"
                                height="62.165"
                                transform="translate(163.095 105.223) rotate(-81.823)"
                                fill="#f74a4a"
                            />
                            <rect
                                id="Rectángulo_9"
                                data-name="Rectángulo 9"
                                width="5.023"
                                height="184.611"
                                transform="translate(154.103 132.478) rotate(-81.823)"
                                fill="#f74a4a"
                            />
                            <rect
                                id="Rectángulo_10"
                                data-name="Rectángulo 10"
                                width="5.023"
                                height="184.611"
                                transform="translate(151.692 149.259) rotate(-81.823)"
                                fill="#f74a4a"
                            />
                            <rect
                                id="Rectángulo_11"
                                data-name="Rectángulo 11"
                                width="5.023"
                                height="184.611"
                                transform="translate(149.28 166.041) rotate(-81.823)"
                                fill="#f74a4a"
                            />
                            <path
                                id="Trazado_44"
                                data-name="Trazado 44"
                                d="M991.208,376.479l-29.49,14.462L811.214,464.754l-138.7-114.631-28.039-23.211-3.781,2.455,31.3,56.44-1.814,121.928L749.4,525.429,759.756,544.1a8.293,8.293,0,0,0,5.3,4.046l107.879,26.316a8.318,8.318,0,0,0,9.439-4.472l6.46-13.416L943.8,568.85a8.319,8.319,0,0,0,10.123-7.793l5.121-133.188,34.974-46.743.1-1.178Z"
                                transform="translate(-575.568 -207.044)"
                                opacity="0.1"
                                style={{ isolation: "isolate" }}
                            />
                            <path
                                id="Trazado_45"
                                data-name="Trazado 45"
                                d="M642.911,328.929,630.471,541.944a8.316,8.316,0,0,0,6.5,8.6L967.32,623.9a8.313,8.313,0,0,0,10.067-7.444l19.36-236.469L813.438,469.9Z"
                                transform="translate(-577.792 -206.606)"
                                fill="#5b8ff5"
                            />
                            <path
                                id="Trazado_46"
                                data-name="Trazado 46"
                                d="M632.461,527.153,804.083,421.27a12.6,12.6,0,0,1,16.046,2.512l153.608,179.15c.559.641.83.181,0,0L632.461,527.153C631.483,526.937,631.61,527.662,632.461,527.153Z"
                                transform="translate(-577.506 -186.939)"
                                opacity="0.1"
                                style={{ isolation: "isolate" }}
                            />
                            <path
                                id="Trazado_47"
                                data-name="Trazado 47"
                                d="M633.506,526.44l169.85-102.6a13.316,13.316,0,0,1,16.938,2.665l152.22,175.062a1.834,1.834,0,0,1-1.778,2.992L634.056,529.8a1.828,1.828,0,0,1-.55-3.356Z"
                                transform="translate(-577.324 -186.402)"
                                fill="#4e7cd6"
                            />
                        </g>
                    </g>
                </svg>
            </div>
        </section>
    );
};

export default Mensaje;
