import React, { Fragment, useContext, useEffect, useState } from 'react';
import M from 'materialize-css';

import EtiquetaProducto from '../Components/EtiquetaProducto';

import { globalContext } from '../Context/GlobalContext';
import { Link } from 'react-router-dom';
import Loader from '../Components/Loader/Loader';

const Productos = () => {
    const { medica, veterinaria, productos } = useContext(globalContext);
    const [productosFinal, setProductosFinal] = useState([]);
    const [categoria, setCategoria] = useState(false);
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        var elems = document.querySelectorAll('.collapsible');
        M.Collapsible.init(elems,);

        if (productos.length > 0) {
            setFlag(true);
            setProductosFinal(productos);
        }
    },[productos]);

    const seleccionarCategoria = (e, id, nombre) => {
        const copia = [...productos];
        let filter = copia.filter(producto => producto.categoria.id === id);

        setProductosFinal(filter);
        setCategoria(nombre);

        let target = document.getElementsByClassName("vinculo-productos");
        if (target.length > 0) {
            target[0].className = "";
        }
        e.target.className = "vinculo-productos";
    }

    const restablecerProductos = () => {
        setProductosFinal(productos);
        setCategoria(false);
    }

    return (
        <Fragment>
            <section className='container contenedor-gd row'>
                <div className='col s12 m8 l4 offset-m2 sticky-desktop'>
                    <ul class="collapsible">
                        <li className='active'>
                            <div class="collapsible-header">Todas las categorías</div>
                            <div class="collapsible-body">
                                {!flag ? (
                                    <Loader />
                                ) : (
                                    <div>
                                        <p className='titulo-3'>Área médica</p>
                                        {medica.map(categoria => (
                                            <button onClick={(e) => {seleccionarCategoria(e, categoria.id, categoria.nombre)}}>{categoria.nombre}</button>
                                        ))}
                                        <div className='divider' />
                                        <p className='titulo-3'>Área veterinaria</p>
                                        {veterinaria.map(categoria => (
                                            <button onClick={(e) => {seleccionarCategoria(e, categoria.id, categoria.nombre)}}>{categoria.nombre}</button>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </li>
                    </ul>
                </div>
                <div style={{minHeight:"75vh"}} className='col s12 m12 l8'>
                    <nav style={{height:"auto"}}>
                        <div class="nav-wrapper">
                            <div class="col s12">
                                <Link to={"/"} class="breadcrumb">Inicio</Link>
                                <button onClick={restablecerProductos} class="breadcrumb">Productos</button>
                                {categoria && (
                                    <span style={{cursor:"default"}} class="breadcrumb">{categoria}</span>
                                )}
                            </div>
                        </div>
                    </nav>
                    {!flag ? (
                        <Loader />
                    ) : (
                        productosFinal.map(producto => (
                            <EtiquetaProducto producto={producto} className={"s6 m6 l4"} />
                        ))
                    )}
                </div>
            </section>
        </Fragment>
    );
};

export default Productos;