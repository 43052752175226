import React from 'react';

import "./Loader.css";

const Loader = () => {
    return (
        <div className='body-load'>
            <div class="load">
                <hr/><hr/><hr/><hr/>
            </div>
        </div>
    );
};

export default Loader;