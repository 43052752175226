import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import logo from "../Images/logo.svg";

import icon6 from "../Images/Icons/6.svg";
import icon7 from "../Images/Icons/7.svg";
import icon8 from "../Images/Icons/8.svg";

import icon9 from "../Images/Icons/9.svg";
import icon10 from "../Images/Icons/10.svg";

import { globalContext } from '../Context/GlobalContext';
import Loader from './Loader/Loader';

const Footer = () => {
    const { categorias } = useContext(globalContext);
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        if (categorias.length > 0) {
            setFlag(true);
        }
    },[categorias]);
    
    return (
        <footer className="page-footer">
            <div className="container contenedor-xgd">
                <div className="row">
                    <div className='col s8 m4 l2 offset-m4 offset-s2' style={{display:"flex"}}>
                        <img src={logo} alt="Andrade's" style={{width:"100%"}} />
                    </div>
                    <div className='col s12 m8 l10 offset-m2'>
                        <div className='col s12 m4 l3'>
                            <p className='titulo-footer'>Inicio</p>
                            <p><Link to={"/nosotros"}>Nosotros</Link></p>
                            <p><Link to={"/productos"}>Productos</Link></p>
                            <p><Link to={"/contactanos"}>Contacto</Link></p>
                        </div>
                        <div className='col s12 m4 l3'>
                            <p className='titulo-footer'>Categorias</p>
                            {!flag ? (
                                <Loader />
                            ) : (
                                categorias.map((categoria, index) => (
                                    index < 4 && (
                                        <p><Link to={'/productos'}>{categoria.nombre}</Link></p>
                                    )
                                ))
                            )}
                        </div>
                        <div className='col s12 m4 l3'>
                            <p className='titulo-footer'>Políticas</p>
                            <p><Link to={"/aviso-de-privacidad"}>Aviso de privacidad</Link></p>
                            <p><Link to={"/terminos-y-condiciones"}>Términos y condiciones</Link></p>
                            <div>
                                <p className='titulo-footer'>Síguenos en:</p>
                                <div className='center-movil' style={{display:"flex", gap:"15px"}}>
                                    <a 
                                        target="_blank" 
                                        rel="noreferrer"
                                        href="https://www.facebook.com/profile.php?id=100090151776439&mibextid=ZbWKwL"
                                    >
                                        <img src={icon9} alt="FaceBook Andre's" style={{width:"50px", borderRadius:"50%"}} />
                                    </a>
                                    <a 
                                        target="_blank" 
                                        rel="noreferrer"
                                        href="https://api.whatsapp.com/send?phone=+523334683632&text=Hola%20me%20podria%20dar%20mas%20informacion"
                                    >
                                        <img src={icon10} alt="WhatsApp Andre's" style={{width:"50px", borderRadius:"50%"}} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col s12 m8 l3 offset-m2 contacto'>
                            <p className='titulo-footer'>Contáctanos</p>
                            <p className='contacto-icono'>
                                <img style={{width:"20px", height:"20px"}} src={icon6} alt="Telefono Andre's" />
                                <a 
                                    target="_blank" 
                                    rel="noreferrer"
                                    href="tel:3334683632"
                                >
                                    33 3468 3632
                                </a>
                            </p>
                            <p className='contacto-icono'>
                                <img style={{width:"20px", height:"20px"}} src={icon7} alt="WhatsApp Andre's" />
                                <a 
                                    target="_blank" 
                                    rel="noreferrer"
                                    href="https://api.whatsapp.com/send?phone=+523334683632&text=Hola%20me%20podria%20dar%20mas%20informacion"
                                >
                                    WhatsApp 33 3468 3632
                                </a>
                            </p>
                            <p className='contacto-icono'>
                                <img style={{width:"20px", height:"20px"}} src={icon8} alt="Ubicacion Andre's" />
                                <a 
                                    target="_blank" 
                                    rel="noreferrer"
                                    href="https://goo.gl/maps/qoi3us5W5v35VoRG8"
                                >
                                    Calle Puerto Altata 717, <br />
                                    Circunvalación Belisario, 44330 Guadalajara, Jal
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">
                <div className="container center">
                    Andrade´s {new Date().getFullYear()} @ All rights reserved.
                </div>
            </div>
        </footer>
    );
};

export default Footer;