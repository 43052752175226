import React from 'react';

import icon9 from "../Images/Icons/9.svg";
import icon10 from "../Images/Icons/10.svg";

const Redes = () => {
    return (
        <div style={{display:"flex", flexDirection:"column", gap:"10px", position:"fixed", right:"25px", top:"200px", zIndex:"999"}}>
            <a
                target="_blank" 
                rel="noreferrer"
                href="https://www.facebook.com/profile.php?id=100090151776439&mibextid=ZbWKwL"
            >
                <img src={icon9} alt="FaceBook Andre's" style={{width:"50px", borderRadius:"50%"}} />
            </a>
            <a 
                target="_blank" 
                rel="noreferrer"
                href="https://api.whatsapp.com/send?phone=+523334683632&text=Hola, ¿me podrías dar más información sobre los productos?"
            >
                <img src={icon10} alt="FaceBook Andre's" style={{width:"50px", borderRadius:"50%"}} />
            </a>
        </div>
    );
};

export default Redes;