import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import EtiquetaProducto from '../Components/EtiquetaProducto';
import useDatabase from '../Hooks/useDatabase';

import { globalContext } from '../Context/GlobalContext';

import icon1 from "../Images/Icons/1.svg";
import icon2 from "../Images/Icons/2.svg";
import icon3 from "../Images/Icons/3.svg";

import img4 from "../Images/4.png";

import logo1 from "../Images/Logos/1.svg";
import logo2 from "../Images/Logos/2.svg";
import logo3 from "../Images/Logos/3.svg";
import logo4 from "../Images/Logos/4.svg";
import logo5 from "../Images/Logos/5.svg";
import logo6 from "../Images/Logos/6.svg";
import logo7 from "../Images/Logos/7.svg";
import logo8 from "../Images/Logos/8.svg";
import Loader from '../Components/Loader/Loader';

const Index = () => {
  const [consulta, setConsulta] = useState({consulta: ""});
  const [result] = useDatabase(consulta);

  const { categorias } = useContext(globalContext);
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        if (categorias.length > 0) {
            setFlag(true);
        }
        if (!result) {
          setConsulta({consulta:"random"})
        }
    },[categorias, result]);

  return (
    <Fragment>
      <section>
        <div className='fondo-index'>
          <p style={{margin:"0px"}}>
            Material de curación <br />
            <span className='rubik'>ANDRADE’S</span>
          </p>
          <Link className='boton-2' style={{marginTop:"20px"}} to={'/productos'}>Ver productos</Link>
        </div>
      </section>

      <section className='row container contenedor-gd center' style={{margin:"50px auto 30px auto"}}>
        <p className='titulo-1'>Sobre nosotros</p>
        <div className='col s12 m4 l4'>
          <div className='borde-1'>
            <img style={{width:"100%"}} src={icon1} alt="Andre's Telefono" />
          </div>
          <p className='titulo-2' style={{marginBottom:"0px"}}>Atención personalizada</p>
          <p style={{marginTop:"5px"}}>
            Comprometidos con nuestros clientes, brindamos la atención que merecen.
          </p>
        </div>
        <div className='col s12 m4 l4'>
          <div className='borde-2'>
            <img style={{width:"100%"}} src={icon2} alt="Andre's Equipo" />
          </div>
          <p className='titulo-2' style={{marginBottom:"0px"}}>Equipo actualizado</p>
          <p style={{marginTop:"5px"}}>
            Ofrecemos productos con base a las necesidades y retos de la actualidad.
          </p>
        </div>
        <div className='col s12 m4 l4'>
          <div className='borde-1'>
            <img style={{width:"100%"}} src={icon3} alt="Andre's Competitividad" />
          </div>
          <p className='titulo-2' style={{marginBottom:"0px"}}>Competitividad</p>
          <p style={{marginTop:"5px"}}>
            Los productos médicos de la más alta calidad y al mejor precio del mercado.
          </p>
        </div>
      </section>

      <section className='fondo-1' style={{padding:"10px 0 50px 0"}}>
        <div className='container contenedor-ch-tablet center'>
          <p className='titulo-1 center'>Productos</p>
          <div className='row' style={{marginBottom:"40px"}}>
            {!result ? (
              <Loader />
            ) : (
              result.map(producto => (
                <EtiquetaProducto producto={producto} className={"s6 m6 l3"} />
              ))
            )}
          </div>
          <Link className='boton-1' to={'/productos'}>Ver más</Link>
        </div>
      </section>

      <section className='container' style={{margin:"10px auto"}}>
        <div className='fondo-2 row' style={{display:"flex", alignItems:"center", justifyContent:"center", flexWrap:"wrap"}}>
          <div className='col s12 m8 l5 xl6' style={{flexDirection:"column"}}>
            <p className='titulo-1' style={{marginTop:"0px"}}>Busca por categoría</p>
            <p style={{letterSpacing:"2px", marginTop:"0px"}}>Encuentra los mejores productos según la categoría que necesites:</p>
            <div className='lista-index'>
              {!flag ? (
                <Loader />
              ) : (
                categorias.map((categoria, index) => (
                  index < 4 && (
                    <p><Link to={'/productos'}>{categoria.nombre}</Link></p>
                  )
                ))
              )}
            </div>
            <Link className='boton-1' to={'/productos'} >Ver todas</Link>
          </div>
          <div className='col s12 m8 l7 xl7' style={{position:"relative", marginRight:"-15%"}}>
            <img src={img4} alt="Productos Médicos" style={{width:"100%"}} />
          </div>
        </div>
      </section>

      <section className='container contenedor-xch center' style={{marginBottom:"80px"}}>
        <p className='titulo-1'>Trabajamos con</p>
        <div className='contenedor-logos'>
          <img src={logo1} alt="SensiMedcal" />
          <img src={logo2} alt="Quirmex" />
          <img src={logo3} alt="3M" />
          <img src={logo4} alt="DL" />
          <img src={logo5} alt="ARROW" />
          <img src={logo6} alt="Degasa" />
          <img src={logo7} alt="Atramat" />
          <img src={logo8} alt="Galia" />
        </div>
      </section>
    </Fragment>
  );
};

export default Index;