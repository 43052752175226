import { useEffect, useState } from "react";
import { useFirestore  } from 'react-redux-firebase';

const useDatabase = ({ consulta, state }) => {
    const firestore = useFirestore();
    const [result, setResult] = useState(false);

    const obtenerRandom = () => {
        let productos = [];
        var busqueda = firestore.collection("productos");
        var key = busqueda.doc().id;
        
        busqueda.where(firestore.FieldPath.documentId(), '>=', key).limit(4).get()
        .then(snapshot => {
            if(snapshot.size > 2) {
                snapshot.forEach(doc => {
                    productos.push({...doc.data(), id: doc.id});
                });
                setResult(productos);
            }
            else {
                busqueda.where(firestore.FieldPath.documentId(), '<', key).limit(4).get()
                .then(snapshot => {
                    snapshot.forEach(doc => {
                        productos.push({...doc.data(), id: doc.id});
                    });
                    setResult(productos);
                })
                .catch(err => {
                    console.log('Error getting documents', err);
                    setResult(err);
                });
            }
        })
        .catch(err => {
            console.log('Error getting documents', err);
            setResult(err);
        });
    }

    const categorias = () => {
        let categorias = [];
        var busqueda = firestore.collection("categorias");
        
        busqueda.get()
        .then(snapshot => {
            if(snapshot.size > 0) {
                snapshot.forEach(doc => {
                    // console.log(doc.id, '=>', doc.data());
                    categorias.push({
                        ...doc.data(),
                        id: doc.id
                    });
                });
            }

            if (consulta === "categorias_productos") {
                productos(categorias);
            } else {
                setResult(categorias);
            }
        })
        .catch(err => {
            console.log('Error getting documents', err);
            setResult(err);
        });
    }

    const productos = (categorias = []) => {
        let productos = [];
        var busqueda = firestore.collection("productos");
        
        busqueda.get()
        .then(snapshot => {
            if(snapshot.size > 0) {
                snapshot.forEach(doc => {
                    // console.log(doc.id, '=>', doc.data());
                    productos.push({
                        ...doc.data(),
                        id: doc.id
                    });
                });
            }

            if (consulta === "categorias_productos") {
                setResult({productos, categorias});
            } else {
                setResult(productos);
            }
        })
        .catch(err => {
            console.log('Error getting documents', err);
            setResult(err);
        });
    }

    const agregarMensaje = () => {
        firestore.collection("mensajes").doc().set(state)
        .then(() => {
            setResult(true);
        });
    }


    useEffect(() => {
        if (consulta === "random") {
            obtenerRandom();
        } else if (consulta === "categorias") {
            categorias();
        } else if (consulta === "productos") {
            productos();
        } else if (consulta === "categorias_productos") {
            categorias();
        }  else if (consulta === "agregar_mensaje") {
            console.log("entro 1");
            agregarMensaje();
        }
        // eslint-disable-next-line
    },[consulta]);

    return [ result ];
}

export default useDatabase;