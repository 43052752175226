import React, { useEffect, useState } from 'react';
import M from 'materialize-css';

import buscador from "../Images/Icons/11.svg";
import { Link } from 'react-router-dom';

const Buscador = ({medica, veterinaria, productos}) => {
    const [flag, setFlag] = useState(false);
    const [productoFinal, setProductoFinal] = useState([]);
    const [productoBuscado, setProductoBuscado] = useState("");
    const [productosCategoria, setProductoCategorias] = useState([]);

    useEffect(() => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);

        if (productos.length > 0) {
            setFlag(true);
            setProductoFinal(productos);
            setProductoCategorias(productosCategoria);
        }
        // eslint-disable-next-line
    }, [productos]);

    const leerProducto = e => {
        let letras = e.target.value;
        let copiaProductos = [...productoFinal];
        let resutadoProductosFiltro = [];

        if(letras.length < productoBuscado.length)
            copiaProductos = [...productosCategoria];

        setProductoBuscado(letras);

        if (letras === "") {
            setProductoFinal(productosCategoria);
        } else {
            copiaProductos.filter(producto => {
                let busqueda = producto.nombre.toUpperCase().indexOf(letras.toUpperCase())
                if (busqueda >= 0) {
                     resutadoProductosFiltro.push(producto);
                }
                return true;
            });
    
            setProductoFinal(resutadoProductosFiltro);
        }
    }

    const leerCategoria = e => {
        let copiaProductos = [...productos];
        let id = e.target.value;

        if(id === "0"){
            setProductoFinal(productos);
            setProductoCategorias(productos);
        } else {
            let filter = copiaProductos.filter(producto => producto.categoria.id === id);
            setProductoFinal(filter);
            setProductoCategorias(filter);
        }

    }

    return (
        <section className='buscador-header'>
            <div class="input-field select-buscador">
                <select onChange={leerCategoria}>
                    <option value="0" selected>Todas las categorias</option>
                    <optgroup label="Área médica">
                        {medica.map(categoria => (
                            <option value={categoria.id}>{categoria.nombre}</option>
                        ))}
                    </optgroup>
                    <optgroup label="Área veterinaria">
                        {veterinaria.map(categoria => (
                            <option value={categoria.id}>{categoria.nombre}</option>
                        ))}
                    </optgroup>
                </select>
            </div>
            <div className='contenedor-input-busqueda'>
                <input autoComplete='off' onChange={leerProducto} className='input-buscador' placeholder="Buscar producto" id="buscador" type="text" />
                {!flag ? (
                    <div className='select-producto'>
                        <p>cargando...</p>
                    </div>
                ) : (
                    <div className='select-producto'>
                        {productoFinal.map(producto => (
                            <Link to={`/producto/${producto.id}`}>{producto.nombre}</Link>
                        ))}
                    </div>
                )}
            </div>
            <Link className='buscar-header' to={"/productos"} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                <img src={buscador} alt="buscador" style={{width:"16px", height:"16px", margin:"0px 10px"}} />
            </Link>
        </section>
    );
};

export default Buscador;