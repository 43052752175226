import React from "react";
import { Link } from "react-router-dom";


const Error = () => {
    return (
        <section style={{margin:"50px auto"}} className="container contenedor-xgd">
            <div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={{width:"100%", height:"100%"}}
                    viewBox="0 0 1132.704 588.907"
                >
                    <g
                        id="Grupo_272"
                        data-name="Grupo 272"
                        transform="translate(-138.422 -270.207)"
                    >
                        <path
                            id="Trazado_1"
                            data-name="Trazado 1"
                            d="M456.44,242.735V215.31a13.319,13.319,0,0,1,13.32-13.32h60.275"
                            transform="translate(-114.11 76.502)"
                            fill="none"
                            stroke="#4a84f7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={8}
                        />
                        <path
                            id="Trazado_2"
                            data-name="Trazado 2"
                            d="M1256.682,789.548l-476.94,5.422-91.9-1.042-205.38-2.34-61.853-.705-39.473-.443-78.337-.892c-42.075-.48-72.5-40.485-61.6-81.128C272.689,591.135,313.744,475.4,395.914,387.8q3.263-3.476,6.6-6.87c69.4-70.6,164.01-115.26,262.253-126.63a426.214,426.214,0,0,1,88.823-1.035q7.886.743,15.787,1.035,10.361.394,20.73,0,7.9-.292,15.787-1.035a426.214,426.214,0,0,1,88.823,1.035c82.9,9.593,163.215,42.893,227.977,95.483a410.65,410.65,0,0,1,40.883,38.018c82.17,87.607,123.218,203.34,154.7,320.625C1329.192,749.063,1298.764,789.068,1256.682,789.548Z"
                            transform="translate(-59.743 64.144)"
                            fill="#65da66"
                            opacity="0.15"
                        />
                        <path
                            id="Trazado_3"
                            data-name="Trazado 3"
                            d="M1177.208,350.734l-8.725,302.4a15.242,15.242,0,0,1-15.24,15.24L472.268,677.1a15.242,15.242,0,0,1-15.24-15.24V381.882c69.4-70.6,164.01-115.26,262.253-126.63h229.95C1032.128,264.844,1112.445,298.144,1177.208,350.734Z"
                            transform="translate(-114.257 63.187)"
                            fill="#2d5fc3"
                        />
                        <rect
                            id="Rectángulo_1"
                            data-name="Rectángulo 1"
                            width="720.18"
                            height="421.845"
                            rx="20.32"
                            transform="translate(365.975 301.481)"
                            fill="#faf2f6"
                        />
                        <path
                            id="Trazado_4"
                            data-name="Trazado 4"
                            d="M1410.245,676.258v73.336a15.242,15.242,0,0,1-15.24,15.24h-98.647V690.2a16.174,16.174,0,0,1,16.177-16.17Z"
                            transform="translate(-324.09 -41.508)"
                            fill="#c2c5dc"
                        />
                        <path
                            id="Trazado_5"
                            data-name="Trazado 5"
                            d="M455.483,907.628c-.39,8.37-1.065,16.868-4,24.713a39.7,39.7,0,0,1-2.332,5.115l-39.473-.443a108.9,108.9,0,0,1,3.57-49.575c-17.318,4.6-37.777.262-51.6-11.145-3.353-2.76-6.473-6.06-7.86-10.178s-.622-9.195,2.745-11.94c2.235-1.815,5.2-2.37,8.04-2.872q37.091-6.514,74.182-13.02c3.12-.547,6.473-1.065,9.322.315,4.575,2.227,5.693,8.138,6.158,13.2A408.046,408.046,0,0,1,455.483,907.628Z"
                            transform="translate(-88.284 -82.429)"
                            fill="#51b752"
                        />
                        <path
                            id="Trazado_6"
                            data-name="Trazado 6"
                            d="M1484.563,707.192V841.659a16.167,16.167,0,0,1-16.17,16.17H1333.926a16.174,16.174,0,0,1-16.177-16.17V707.192a16.174,16.174,0,0,1,16.177-16.17h134.468A16.167,16.167,0,0,1,1484.563,707.192Z"
                            transform="translate(-329.438 -45.755)"
                            fill="#faf2f6"
                        />
                        <path
                            id="Trazado_7"
                            data-name="Trazado 7"
                            d="M1101.393,232.642v48.652H487.968V247.882a15.242,15.242,0,0,1,15.24-15.24Z"
                            transform="translate(-121.992 68.839)"
                            fill="#4a84f7"
                        />
                        <path
                            id="Trazado_8"
                            data-name="Trazado 8"
                            d="M1412.623,247.882v33.413H1305.868V232.642h91.515A15.242,15.242,0,0,1,1412.623,247.882Z"
                            transform="translate(-326.467 68.839)"
                            fill="#2d5fc3"
                        />
                        <path
                            id="Trazado_9"
                            data-name="Trazado 9"
                            d="M1412.623,297.512v27.27l-98.512,4.417a8.247,8.247,0,0,1-8.243-8.242V297.512Z"
                            transform="translate(-326.467 52.622)"
                            fill="#c2c5dc"
                        />
                        <path
                            id="Trazado_10"
                            data-name="Trazado 10"
                            d="M1458.1,199.185V287.76a8.242,8.242,0,0,1-8.243,8.242H1325.99a8.247,8.247,0,0,1-8.242-8.242V199.185a8.247,8.247,0,0,1,8.242-8.243h123.863A8.242,8.242,0,0,1,1458.1,199.185Z"
                            transform="translate(-329.438 79.265)"
                            fill="#faf2f6"
                        />
                        <path
                            id="Trazado_11"
                            data-name="Trazado 11"
                            d="M585.806,486.917v57.544a8.51,8.51,0,0,1-8.505,8.513l-89.332-1.719V478.412H577.3A8.508,8.508,0,0,1,585.806,486.917Z"
                            transform="translate(-121.992 7.397)"
                            fill="#c2c5dc"
                        />
                        <path
                            id="Trazado_12"
                            data-name="Trazado 12"
                            d="M1458.1,199.185v12.878H1317.748V199.185a8.247,8.247,0,0,1,8.242-8.243h123.863A8.242,8.242,0,0,1,1458.1,199.185Z"
                            transform="translate(-329.438 79.265)"
                            fill="#f74a4a"
                        />
                        <path
                            id="Trazado_13"
                            data-name="Trazado 13"
                            d="M550.523,472.657V536.64a8.509,8.509,0,0,1-8.505,8.512H380.993a8.509,8.509,0,0,1-8.505-8.512V472.657a8.508,8.508,0,0,1,8.505-8.505H542.018A8.508,8.508,0,0,1,550.523,472.657Z"
                            transform="translate(-93.122 10.962)"
                            fill="#faf2f6"
                        />
                        <path
                            id="Trazado_14"
                            data-name="Trazado 14"
                            d="M550.523,472.657v7.883H372.488v-7.883a8.508,8.508,0,0,1,8.505-8.505H542.018A8.508,8.508,0,0,1,550.523,472.657Z"
                            transform="translate(-93.122 10.962)"
                            fill="#c2c5dc"
                        />
                        <path
                            id="Trazado_15"
                            data-name="Trazado 15"
                            d="M1484.563,707.192v21.788H1317.748V707.192a16.174,16.174,0,0,1,16.177-16.17h134.468A16.167,16.167,0,0,1,1484.563,707.192Z"
                            transform="translate(-329.438 -45.755)"
                            fill="#f74a4a"
                        />
                        <text
                            id="ERROR"
                            transform="translate(323.68 528.858)"
                            fill="#f74a4a"
                            fontSize={27}
                            fontFamily="Poppins-Bold, Poppins"
                            fontWeight={700}
                        >
                            <tspan x={0} y={0}>
                                ERROR
                            </tspan>
                        </text>
                        <circle
                            id="Elipse_1"
                            data-name="Elipse 1"
                            cx="42.774"
                            cy="42.774"
                            r="42.774"
                            transform="translate(1028.945 700.332)"
                            fill="#f74a4a"
                        />
                        <circle
                            id="Elipse_2"
                            data-name="Elipse 2"
                            cx="33.684"
                            cy="33.684"
                            r="33.684"
                            transform="translate(1038.033 709.422)"
                            fill="#faf2f6"
                        />
                        <path
                            id="Trazado_16"
                            data-name="Trazado 16"
                            d="M1426.239,814.976l14.933-14.933a2.673,2.673,0,0,0-3.78-3.781L1422.459,811.2l-14.933-14.934a2.673,2.673,0,0,0-3.781,3.781l14.933,14.933-14.933,14.933a2.673,2.673,0,0,0,3.781,3.781l14.933-14.933,14.933,14.933a2.673,2.673,0,0,0,3.78-3.781Z"
                            transform="translate(-350.74 -71.87)"
                            fill="#f74a4a"
                        />
                        <path
                            id="Trazado_17"
                            data-name="Trazado 17"
                            d="M1520.489,511.872l7.467-7.467a1.337,1.337,0,0,0-1.891-1.89l-7.466,7.466-7.467-7.466a1.336,1.336,0,0,0-1.89,1.89l7.466,7.467-7.466,7.466a1.336,1.336,0,0,0,1.89,1.89l7.467-7.466,7.466,7.466a1.337,1.337,0,0,0,1.891-1.89Z"
                            transform="translate(-377.213 1.469)"
                            fill="#4a84f7"
                        />
                        <path
                            id="Trazado_18"
                            data-name="Trazado 18"
                            d="M582.845,336.513l7.466-7.467a1.336,1.336,0,0,0-1.89-1.89l-7.466,7.467-7.467-7.467a1.336,1.336,0,0,0-1.89,1.89l7.466,7.467-7.466,7.466a1.337,1.337,0,0,0,1.89,1.891l7.467-7.467,7.466,7.467a1.337,1.337,0,1,0,1.89-1.891Z"
                            transform="translate(-142.802 45.309)"
                            fill="#4a84f7"
                        />
                        <path
                            id="Trazado_19"
                            data-name="Trazado 19"
                            d="M856.21,892.72l7.467-7.467a1.337,1.337,0,0,0-1.891-1.89l-7.466,7.466-7.467-7.466a1.336,1.336,0,0,0-1.89,1.89l7.466,7.467-7.466,7.466a1.337,1.337,0,0,0,1.89,1.891l7.467-7.467,7.466,7.467a1.337,1.337,0,0,0,1.891-1.891Z"
                            transform="translate(-211.143 -93.743)"
                            fill="#4a84f7"
                        />
                        <circle
                            id="Elipse_3"
                            data-name="Elipse 3"
                            cx="4.99"
                            cy="4.99"
                            r="4.99"
                            transform="translate(1006.314 659.255)"
                            fill="#faf2f6"
                        />
                        <circle
                            id="Elipse_4"
                            data-name="Elipse 4"
                            cx="4.99"
                            cy="4.99"
                            r="4.99"
                            transform="translate(1025.027 659.255)"
                            fill="#faf2f6"
                        />
                        <circle
                            id="Elipse_5"
                            data-name="Elipse 5"
                            cx="4.99"
                            cy="4.99"
                            r="4.99"
                            transform="translate(1001.324 275.776)"
                            fill="#faf2f6"
                        />
                        <circle
                            id="Elipse_6"
                            data-name="Elipse 6"
                            cx="4.99"
                            cy="4.99"
                            r="4.99"
                            transform="translate(402.154 320.817)"
                            fill="#faf2f6"
                        />
                        <circle
                            id="Elipse_7"
                            data-name="Elipse 7"
                            cx="4.99"
                            cy="4.99"
                            r="4.99"
                            transform="translate(433.164 320.817)"
                            fill="#faf2f6"
                        />
                        <circle
                            id="Elipse_8"
                            data-name="Elipse 8"
                            cx="4.99"
                            cy="4.99"
                            r="4.99"
                            transform="translate(287.74 478.317)"
                            fill="#faf2f6"
                        />
                        <circle
                            id="Elipse_9"
                            data-name="Elipse 9"
                            cx="4.99"
                            cy="4.99"
                            r="4.99"
                            transform="translate(306.453 478.317)"
                            fill="#faf2f6"
                        />
                        <path
                            id="Trazado_20"
                            data-name="Trazado 20"
                            d="M1355.333,248.7l30.475,25.4,16.307-18.178,28.6,24.327"
                            transform="translate(-338.833 64.826)"
                            fill="none"
                            stroke="#f74a4a"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={8}
                        />
                        <path
                            id="Trazado_21"
                            data-name="Trazado 21"
                            d="M1448.225,293.692l10.541-10.542a1.369,1.369,0,0,1,2.335.889l.693,11.99h-12.6A1.368,1.368,0,0,1,1448.225,293.692Z"
                            transform="translate(-361.955 56.313)"
                            fill="#f74a4a"
                        />
                        <path
                            id="Trazado_30"
                            data-name="Trazado 30"
                            d="M664.271,755.074H503.208a15.242,15.242,0,0,1-15.24-15.24V713.877c9.638-4,33.63-13.538,51.592-17.085,23-4.545,64.608,4.193,64.608,4.193L543.91,645.1l4.26-10.11s53.857,31.328,57.42,33.825,40.283,34.575,42.06,42.773,11.055,30.172,11.055,30.172S661.031,747.3,664.271,755.074Z"
                            transform="translate(-121.992 -31.748)"
                            fill="#c2c5dc"
                        />
                        <g
                            id="Grupo_2"
                            data-name="Grupo 2"
                            transform="translate(249.703 562.592)"
                        >
                            <path
                                id="Trazado_31"
                                data-name="Trazado 31"
                                d="M567.185,610.855l-6.95-4.547c-1.356-4.558-4.178-10.5-6.411-15.893a1.363,1.363,0,0,0-2.615.677c.278,2.478.726,4.961.878,6.845-3.775-5.131-8.279-10.363-12.054-15.493a4.808,4.808,0,0,0-1.34-1.384,1.44,1.44,0,0,0-2.313.954,2.466,2.466,0,0,0,.3,1.515l4.922,9.335c-4.963-3.5-8.735-7.553-12.886-10.466a1.792,1.792,0,0,0-2.653,2.228c1.826,3.85,6.493,10.126,8.813,12.985l-11.936-3.7a1.044,1.044,0,0,0-.845,1.894c4.048,2.423,8.577,4.826,11.836,6.459a11.274,11.274,0,0,0-7.519,6.006,14.987,14.987,0,0,1,15.965,7.838,23.622,23.622,0,0,1,14.2,5.328C560.056,617.792,563.7,614.5,567.185,610.855Z"
                                transform="translate(-380.098 -580.789)"
                                fill="#dd8e58"
                            />
                            <path
                                id="Trazado_32"
                                data-name="Trazado 32"
                                d="M635.668,729.406l-.007.008a50.336,50.336,0,0,1-2.01,7.388c-6.42,17.962-25.11,31.792-44.025,29.37,10.552,40.065,21.8,79.763,32.43,119.79l-205.38-2.34a336.716,336.716,0,0,1,5.408-65.04c-14.378,4.177-29.055,8.392-44.018,7.845s-30.525-6.682-38.693-19.23c-9.09-13.958-7.192-32.34-2.61-48.36,11.46-40.118,29.5-68.333,64.71-90.713,12.923,5.753,20.715,18.848,25.822,32.032-12.15,13.133-15.69,20.872-25.245,36a353.455,353.455,0,0,1,66.2-28.08c22.455-6.915,43.118-11.212,66.54-9.337,11.055.893,21.352.427,32.445.487-13.68-15.772-43.737-49.29-60.515-61.71C504.661,624.969,517.66,619,523.2,619.6c20.9,5.557,41.52,13.583,60.1,24.645,16.388,9.758,31.875,21.847,42.12,37.927C634.288,696.091,638.773,713.371,635.668,729.406Z"
                                transform="translate(-332.937 -590.481)"
                                fill="#faf2f6"
                            />
                            <path
                                id="Trazado_33"
                                data-name="Trazado 33"
                                d="M534.048,637.483c1.609-8,.476-16.97-4.908-23.1s-15.6-8.17-21.936-3.028c-8.956-3.716-18.353-7.109-28.045-6.825s-19.817,5.016-23.944,13.79c-1.911,4.064-2.411,8.627-2.885,13.093q-.758,7.139-1.515,14.277L449.3,659.955c-.619,5.829-1.236,11.735-.355,17.531s3.441,11.566,8.105,15.117c6.134,4.672,14.852,4.664,22.065,1.942s13.271-7.769,19.177-12.726c8.319-6.982,17.947-13.531,25.332-21.5C529.014,654.5,532.518,645.094,534.048,637.483Z"
                                transform="translate(-361.83 -586.721)"
                                fill="#583701"
                            />
                            <path
                                id="Trazado_34"
                                data-name="Trazado 34"
                                d="M493.685,766.018s39.4,106.789,17.293,154.835l87.32.994S583.526,805.7,533.347,770.034Z"
                                transform="translate(-373.124 -627.096)"
                                fill="#65da66"
                            />
                            <path
                                id="Trazado_35"
                                data-name="Trazado 35"
                                d="M532.568,669.023c1.858,8.93,3.725,17.976,3.286,27.086s-3.449,18.443-10.044,24.744a27,27,0,0,1-44.276-11c-1.511-4.538-4.474-11.313-5.144-16.049a9.588,9.588,0,1,1,3.679-15.651,29.978,29.978,0,0,0,22.594-15.919c9.662,4.912,23.066-.425,26.708-10.635C530.366,657.3,531.389,663.357,532.568,669.023Z"
                                transform="translate(-365.611 -598.492)"
                                fill="#dd8e58"
                            />
                            <path
                                id="Trazado_36"
                                data-name="Trazado 36"
                                d="M532.723,761.617A83.854,83.854,0,0,1,509,765.892a26.486,26.486,0,0,1-7.845-.735,11.316,11.316,0,0,1-6.4-4.342c-5.378-8.04-2.677-18.832-3.562-28.463,12.128,1.238,24.3,4.133,36.427,5.37q.2,3.364.683,6.7a80.627,80.627,0,0,0,4.425,17.2Z"
                                transform="translate(-372.5 -618.68)"
                                fill="#dd8e58"
                            />
                            <path
                                id="Trazado_37"
                                data-name="Trazado 37"
                                d="M531.456,755.325c-32.97,6.69-34.208-12.743-34.208-12.743,11.408,13.905,32.565,4.4,32.565,4.4A81.244,81.244,0,0,0,531.456,755.325Z"
                                transform="translate(-374.015 -621.237)"
                                fill="#c47239"
                            />
                            <path
                                id="Trazado_38"
                                data-name="Trazado 38"
                                d="M427.276,670.731,436.7,662.8c-1.533-6.7-3.027-14.192-4.56-20.89a4.253,4.253,0,0,1-.143-1.88,1.457,1.457,0,0,1,1.287-1.2c.786,0,1.291.808,1.639,1.513l4.536,9.2a67.644,67.644,0,0,1,1.643-21.714,1.006,1.006,0,0,1,1.957,0l3.752,15.676a82.164,82.164,0,0,1,2.261-23.954.954.954,0,0,1,1.863.04l5.149,26.283a125.544,125.544,0,0,1,6.794-12.254.941.941,0,0,1,1.732.509,50.97,50.97,0,0,1-3.66,18.326l9.857-3.8a.8.8,0,0,1,.94,1.225l-17.3,23.7c-4.983,6.826-12.442,11.719-18.668,17.435C434.249,683.494,432.6,676.248,427.276,670.731Z"
                                transform="translate(-356.522 -590.296)"
                                fill="#dd8e58"
                            />
                            <path
                                id="Trazado_39"
                                data-name="Trazado 39"
                                d="M707.215,763.289a50.328,50.328,0,0,1-2.01,7.388c-6.42,17.962-25.11,31.792-44.025,29.37,0,0-18.758-31.95-42.255-44.985C636.925,763.177,688.135,783.63,707.215,763.289Z"
                                transform="translate(-404.434 -624.357)"
                                fill="#c2c5dc"
                            />
                        </g>
                        <circle
                            id="Elipse_10"
                            data-name="Elipse 10"
                            cx="13.097"
                            cy="13.097"
                            r="13.097"
                            transform="translate(865.611 785.881)"
                            fill="#f74a4a"
                        />
                        <circle
                            id="Elipse_11"
                            data-name="Elipse 11"
                            cx="5.08"
                            cy="5.08"
                            r="5.08"
                            transform="translate(873.629 793.898)"
                            fill="#f5e9cb"
                        />
                        <path
                            id="Trazado_40"
                            data-name="Trazado 40"
                            d="M1035.275,795.1v16.77a25.168,25.168,0,0,1-25.162,25.17H802.2a25.168,25.168,0,0,1-25.162-25.17V795.1Z"
                            transform="translate(-194.259 -71.775)"
                            fill="#b43333"
                        />
                        <path
                            id="Trazado_41"
                            data-name="Trazado 41"
                            d="M1042.048,752.185v25.9H783.808v-25.9a25.162,25.162,0,0,1,25.162-25.163h207.915A25.162,25.162,0,0,1,1042.048,752.185Z"
                            transform="translate(-195.952 -54.755)"
                            fill="#c2c5dc"
                        />
                        <circle
                            id="Elipse_12"
                            data-name="Elipse 12"
                            cx="45.779"
                            cy="45.779"
                            r="45.779"
                            transform="translate(179.23 322.736)"
                            fill="#f74a4a"
                        />
                        <circle
                            id="Elipse_13"
                            data-name="Elipse 13"
                            cx="10.357"
                            cy="10.357"
                            r="10.357"
                            transform="translate(138.422 597.818)"
                            fill="#f74a4a"
                        />
                        <circle
                            id="Elipse_14"
                            data-name="Elipse 14"
                            cx="10.357"
                            cy="10.357"
                            r="10.357"
                            transform="translate(1250.412 464.757)"
                            fill="#f74a4a"
                        />
                        <text
                            id="_404"
                            data-name={404}
                            transform="translate(568.096 576.162)"
                            fill="#f74a4a"
                            fontSize={157}
                            fontFamily="Poppins-Bold, Poppins"
                            fontWeight={700}
                        >
                            <tspan x={0} y={0}>
                                404
                            </tspan>
                        </text>
                        <text
                            id="Ops..._no_encontramos_la_página_"
                            data-name="Ops... no encontramos la página!"
                            transform="translate(532.5 617.532)"
                            fill="#f74a4a"
                            fontSize={22}
                            fontFamily="Poppins-Bold, Poppins"
                            fontWeight={700}
                        >
                            <tspan x={0} y={0}>
                                Ops... no encontramos la página!
                            </tspan>
                        </text>
                        <g
                            id="Back_Home"
                            data-name="Back Home"
                            transform="translate(596.947 662.924)"
                        >
                            <Link to={"/"}>
                                <rect
                                    id="Rectángulo_6"
                                    data-name="Rectángulo 6"
                                    width="258.238"
                                    height="92.997"
                                    rx="33.551"
                                    fill="#f74a4a"
                                />
                                <text
                                    id="Back_Home-2"
                                    data-name="Back Home"
                                    transform="translate(64.758 51.813)"
                                    fill="#fff"
                                    fontSize={22}
                                    fontFamily="Poppins-Medium, Poppins"
                                    fontWeight={500}
                                >
                                    <tspan x={0} y={0}>
                                        Ir al inicio
                                    </tspan>
                                </text>
                            </Link>
                        </g>
                    </g>
                </svg>
            </div>
        </section>
    );
};

export default Error;
