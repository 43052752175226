import React from 'react';
import { Link } from 'react-router-dom';

const EtiquetaProducto = ({producto, className}) => {
    const scroll = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    return (
        <Link to={`/producto/${producto.id}`}>
            <div onClick={scroll} className={'etiqueta-producto col ' + className}>
                <div className='imagen-cuadrada'>
                    <img src={producto.galeria[0].result} alt={producto.nombre} />
                </div>
                <p style={{marginBottom:"5px"}}><b>{producto.nombre}</b></p>
                <div class="divider" style={{width:"70%"}}></div>
                <p style={{marginTop:"5px", marginBottom:"0"}} className='texto-2'>
                    $ {producto.precio_menudeo.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00 MXN
                </p>
            </div>
        </Link>
    );
};

export default EtiquetaProducto;