import React, { useEffect, useState } from 'react';

import useDatabase from '../Hooks/useDatabase';

const globalContext = React.createContext();

const GlobalContext = ({ children }) => {
    const [categorias, setCategorias] = useState([]);
    const [productos, setProductos] = useState([]);
    const [medica, setMedica] = useState([]);
    const [veterinaria, setVeterinaria] = useState([]);
    const [result] = useDatabase({consulta:"categorias_productos"});

    useEffect(() => {
        if (result) {
            let medicaArray = [], 
            veterinariaArray = [];

            result.categorias.map(categoria => {
                if (categoria.area.indexOf("medica") >= 0) {
                    medicaArray.push(categoria);
                }

                if (categoria.area.indexOf("veterinaria") >= 0) {
                    veterinariaArray.push(categoria);
                }

                return true;
            });

            setCategorias(result.categorias);
            setMedica(medicaArray);
            setVeterinaria(veterinariaArray);
            setProductos(result.productos);
        }
        // eslint-disable-next-line
      }, [result]);

    return ( 
        <globalContext.Provider value={{categorias, medica, veterinaria, productos}}>
            {children}
        </globalContext.Provider>
    );
}
 
export {GlobalContext, globalContext};