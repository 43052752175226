import React, { Fragment } from 'react';

import img5 from "../Images/5.png";
import img6 from "../Images/6.png";

import icon1 from "../Images/Icons/1.svg";
import icon2 from "../Images/Icons/2.svg";
import icon3 from "../Images/Icons/3.svg";


const Nosotros = () => {
    return (
        <Fragment>
            <section>
                <div className='fondo-nosotros'>
                    <p>
                        Material de curación <br />
                        <span className='rubik'>ANDRADE’S</span>
                    </p>
                </div>
            </section>

            <section className='container contenedor-xch row' style={{display:"flex", alignItems:"center", flexWrap:"wrap", marginTop:"50px"}}>
                <div className='col s12 m8 l6 offset-m2'>
                    <img src={img5} alt="Cateter Andrade's" style={{width:"100%"}} />
                </div>
                <div className='col s12 m8 l6 offset-m2 center-movil-text' style={{color:"#707070"}}>
                    <p>
                        Somos una empresa dedicada a la distribución de material e insumos 
                        hospitalarios.
                    </p>
                    <p>
                        Tenemos una amplia gama de productos, que incluyen material de curación, 
                        anestésicos, artículos para cirugía,  instrumental médico y más.
                    </p>
                </div>
            </section>

            <section className='row container contenedor-gd center' style={{margin:"50px auto 30px auto"}}>
                <p className='titulo-1'>¿Por qué escogernos?</p>
                <div className='col s12 m4 l4'>
                    <div className='borde-1'>
                        <img style={{width:"100%"}} src={icon1} alt="Andre's Telefono" />
                    </div>
                    <p className='titulo-2' style={{marginBottom:"0px"}}>Atención personalizada</p>
                    <p style={{marginTop:"5px"}}>
                        Comprometidos con nuestros clientes, brindamos la atención que merecen.
                    </p>
                </div>
                <div className='col s12 m4 l4'>
                    <div className='borde-2'>
                        <img style={{width:"100%"}} src={icon2} alt="Andre's Equipo" />
                    </div>
                    <p className='titulo-2' style={{marginBottom:"0px"}}>Equipo actualizado</p>
                    <p style={{marginTop:"5px"}}>
                        Ofrecemos productos con base a las necesidades y retos de la actualidad.
                    </p>
                </div>
                <div className='col s12 m4 l4'>
                    <div className='borde-1'>
                        <img style={{width:"100%"}} src={icon3} alt="Andre's Competitividad" />
                    </div>
                    <p className='titulo-2' style={{marginBottom:"0px"}}>Competividad</p>
                    <p style={{marginTop:"5px"}}>
                        Los productos médicos de la más alta calidad y al mejor precio del mercado.
                    </p>
                </div>
            </section>

            <section className='fondo-1' style={{marginBottom:"50px"}}>
                <div className='container contenedor-xch row' style={{display:"flex", alignItems:"center", flexWrap:"wrap"}}>
                    <div className='col s12 m8 l6 offset-m2 order-2 center-movil-text' style={{color:"#707070"}}>
                        <p>
                            Suministramos y distribuimos a hospitales, consultorios médicos, quirófanos, 
                            laboratorios, veterinarias y púbico en general. 
                        </p>
                        <p>
                            Con mas de 10 años de experiencia en la distribución de dispositivos médicos, 
                            entendemos las complejidades que enfrentan nuestros clientes.
                        </p>
                    </div>
                    <div className='col s12 m8 l6 offset-m2 order-1'>
                        <img src={img6} alt="Cateter Andrade's" style={{width:"100%"}} />
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default Nosotros;