import React, { Fragment, useState } from 'react';

import icon4 from "../Images/Icons/4.svg";
import icon5 from "../Images/Icons/5.svg";
import icon10 from "../Images/Icons/10.svg";

import useDatabase from '../Hooks/useDatabase';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Contacto = () => {
    const history = useNavigate();
    const [consulta, setConsulta] = useState({consulta: ""});
    const [state, setState] = useState({
        nombre:"",
        celular:"",
        correo:"",
        informacion:"",
        estado:false
    });

    const [ result ] = useDatabase(consulta);

    useEffect(() => {
        if (result) {
            history("/envio-exitoso")
        }

    // eslint-disable-next-line
    },[result]);


    const enviar = e => {
        e.preventDefault();

        setConsulta({consulta:"agregar_mensaje", state});
    }

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    return (
        <Fragment>
            <section>
                <div className='fondo-index'>
                    <p>
                        ¡Contáctanos!
                    </p>
                </div>
            </section>

            <section className='container row' style={{marginTop:"50px", display:"flex", flexWrap:"wrap"}}>
                <div className='col s6 m4 l4 order-1' style={{display:"flex", alignItems:"center", flexDirection:"column", textAlign:"center", gap:"10px"}}>
                    <img src={icon4} alt="Telefono Andrade's" style={{width:"50px"}} />
                    <a 
                        target="_blank" 
                        style={{color:"#404040"}}
                        rel="noreferrer"
                        href="tel:3334683632"
                    >
                        33 3468 3632
                    </a>
                </div>
                <div className='col s12 m4 l4 order-3' style={{display:"flex", alignItems:"center", flexDirection:"column", textAlign:"center", gap:"10px"}}>
                    <img src={icon5} alt="Ubicacion Andrade's" style={{width:"50px"}} />
                    <a 
                        target="_blank" 
                        style={{color:"#404040"}}
                        rel="noreferrer"
                        href="https://goo.gl/maps/qoi3us5W5v35VoRG8"
                    >
                        Calle Puerto Altata 717, <br />
                        Circunvalación Belisario, 44330 Guadalajara, Jal
                    </a>
                </div>
                <div className='col s6 m4 l4 order-2' style={{display:"flex", alignItems:"center", flexDirection:"column", textAlign:"center", gap:"10px"}}>
                    <img src={icon10} alt="WhatsApp Andrade's" style={{width:"50px"}} />
                    <a 
                        target="_blank" 
                        style={{color:"#404040"}}
                        rel="noreferrer"
                        href="https://api.whatsapp.com/send?phone=+523334683632&text=Hola%20me%20podria%20dar%20mas%20informacion"
                    >
                        WhatsApp 33 3468 3632
                    </a>
                </div>
            </section>

            <section className='container margen-1' style={{margin:"90px auto 50px auto"}}>
                <p className='titulo-4 center-movil-text'>¿Cómo podemos ayudarte?</p>
                <form onSubmit={enviar} className='row'>
                    <div class="input-field col s12 m4">
                        <input onChange={leerDato} placeholder="Nombre" name="nombre" type="text" class="validate formulario-contacto" required />
                    </div>
                    <div class="input-field col s12 m4">
                        <input onChange={leerDato} placeholder="Celular" name="celular" type="text" class="validate formulario-contacto" required />
                    </div>
                    <div class="input-field col s12 m4">
                        <input onChange={leerDato} placeholder="Correo" name="correo" type="email" class="validate formulario-contacto" required />
                    </div>
                    <div class="input-field col s12">
                        <textarea onChange={leerDato} placeholder='Mensaje' name="informacion" class="materialize-textarea formulario-textarea" required></textarea>
                    </div>
                    <button type="submit" className='boton-1'>ENVIAR</button>
                </form>
            </section>

            <section className='container' style={{marginBottom:"100px"}}>
                <iframe 
                    title='Ubicacion Andrades Google Maps'
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.3724713224815!2d-103.32012208452349!3d20.69509420445978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b1a164a7def7%3A0xba2aea89bbf449ea!2sC.%20Puerto%20Altata%20717%2C%20Circunvalaci%C3%B3n%20Belisario%2C%2044330%20Guadalajara%2C%20Jal.!5e0!3m2!1ses!2smx!4v1674225059580!5m2!1ses!2smx" 
                    style={{width:"100%", height:"345px", border:"none"}}
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
            </section>
        </Fragment>
    );
};

export default Contacto;