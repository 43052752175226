import React, { Fragment, useContext, useEffect } from 'react';
import M from 'materialize-css';

import Buscador from './Buscador';

import icon13 from "../Images/Icons/13.svg";
import logo from "../Images/logo.png";
import { Link } from 'react-router-dom';

import { globalContext } from '../Context/GlobalContext';


const Header = () => {
    const { medica, veterinaria, productos } = useContext(globalContext);

    useEffect(() => {
        var elems2 = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems2);
    }, []);

    return (
        <Fragment>
            <nav>
                <div className="nav-wrapper">
                    <Link to={"/"} className="brand-logo" style={{marginTop:"10px"}}>
                        <img src={logo} alt="Andrade's Logo" style={{height:"100px"}} />
                    </Link>
                    <a href="#!" data-target="mobile-demo" className="sidenav-trigger" translate="no">
                        <img src={icon13} alt="Menu de opciones" />
                    </a>
                    <div className='hide-on-med-and-down' style={{display:"flex", justifyContent:"center"}}>
                        <Buscador medica={medica} veterinaria={veterinaria} productos={productos} />
                    </div>
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <ul className="hide-on-med-and-down">
                            <li><Link to={"/"}>Inicio</Link></li>
                            <li><Link to={"/nosotros"}>Nosotros</Link></li>
                            <li><Link to={"/productos"}>Productos</Link></li>
                            <li><Link to={"/contactanos"}>Contacto</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>

            <ul className="sidenav" id="mobile-demo">
                <li><Link to={"/"}>Inicio</Link></li>
                <li><Link to={"/nosotros"}>Nosotros</Link></li>
                <li><Link to={"/productos"}>Productos</Link></li>
                <li><Link to={"/contactanos"}>Contacto</Link></li>
            </ul>
        </Fragment>
    );
};

export default Header;