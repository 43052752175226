import React, { Fragment, useContext, useEffect, useState } from 'react';
import M from 'materialize-css';
import { Link, useParams } from 'react-router-dom';
import EtiquetaProducto from '../Components/EtiquetaProducto';
import Loader from '../Components/Loader/Loader';

import { globalContext } from '../Context/GlobalContext';


const Producto = () => {
    const { productos } = useContext(globalContext);
    const { id } = useParams();

    const [flag, setFlag] = useState(false);
    const [producto, setProducto] = useState({});
    const [categoria, setCategoria] = useState({});
    const [productosSimilares, setProductosSimilares] = useState([]);
    const [imagenActual, setImagenActual] = useState("");

    var elems = document.querySelectorAll('.materialboxed');
    M.Materialbox.init(elems);

    useEffect(() => {
        if (productos.length > 0) {

            let filtro = productos.filter(producto => producto.id === id);

            setImagenActual(filtro[0].galeria[0].result)
            setProducto(filtro[0]);
            filtro[0].categoria.get()
            .then(doc => {
                setCategoria(doc.data());
            });

            productoRemoendados(filtro[0]);
        }
        // eslint-disable-next-line 
    },[productos, id]);
    
    const productoRemoendados = (producto) => {
        let filtro = productos.filter(productoFiltro => productoFiltro.categoria.id === producto.categoria.id);
        setProductosSimilares(filtro);
        setFlag(true);
    }

    const createMarkup = descripcion => {
        return {__html: descripcion};
    }

    const seleccionarImagen = imagen => {
        setImagenActual(imagen.result);
    }

    return (
        <Fragment>
            {!flag ? (
                <Loader />
            ) : (
                <Fragment>
                    <section className='container row'>
                        <nav style={{height:"auto"}} className='col s12 m8 l12 offset-m2'>
                            <div class="nav-var-propio nav-wrapper">
                                <div class="col s12">
                                    <Link to={"/"} class="breadcrumb">Inicio</Link>
                                    <Link to={"/productos"} class="breadcrumb">Productos</Link>
                                    <Link to={"/productos"} class="breadcrumb">{categoria.nombre}</Link>
                                    <spant class="breadcrumb">{producto.nombre}</spant>
                                </div>
                            </div>
                        </nav>
                        <div className='col s12 m8 l6 offset-m2'>
                            <div className='galeria'>
                                <div className='carrusel-galeria'>
                                    {producto.galeria.map(imagen => (
                                        <img onClick={() => {seleccionarImagen(imagen)}} src={imagen.result} alt={producto.nombre} />
                                    ))}
                                </div>
                                <div className='imagen-cuadrada-galeria'>
                                    <img className='materialboxed' src={imagenActual} alt={producto.nombre} />
                                </div>
                            </div>
                        </div>
                        <div className='col s12 m8 l6 offset-m2'>
                            <p className='titulo-4' style={{marginBottom:"0"}}>{producto.nombre}</p>
                            <div style={{display:"flex", gap:"50px", marginBottom:"20px"}}>
                                <p className='titulo-4 etiqueta-mayoreo'>$ {producto.precio_mayoreo.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00 MXN</p>
                                <p className='titulo-4 etiqueta-menudeo' style={{color:"#919191"}}>$ {producto.precio_menudeo.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00 MXN</p>
                            </div>
                            <a 
                                href={"https://api.whatsapp.com/send?phone=+523334683632&text=Hola me podria dar informacion sobre este producto: " + window.location.href} 
                                rel="noreferrer" 
                                target="_blank" 
                                className='boton-1'
                            >Mandar Whatapp al vendedor</a>
                            {producto.mercado_libre && (
                                <Fragment>
                                    <div className='divider' style={{margin:"30px 0"}} />
                                    <p>
                                        <span className='titulo-2'>LINK MERCADO LIBRE</span> <br />
                                        <a rel="noreferrer" target="_blank" href={producto.mercado_libre} style={{color:"#919191"}}>Haz clic aqui para ver el producto en Mercado Libre</a>
                                    </p>
                                </Fragment>
                            )}
                        </div>
                        <div className='col s12 m8 s12 offset-m2' style={{marginTop:"100px"}}>
                            <p className='titulo-2'>DESCRIPCIÓN</p>
                            <div className='divider' />
                            <div style={{color:"#919191"}} dangerouslySetInnerHTML={createMarkup(producto.descripcion)}></div>
                        </div>
                    </section>
                    
                    <section className='container contenedor-xch center-movil-text'>
                        <p className='titulo-1'>Productos relacionados</p>
                        <div className='row'>
                            {productosSimilares.map(producto => (
                                <EtiquetaProducto producto={producto} className={"s12 m6 l4"} />
                            ))}
                        </div>
                    </section>
                </Fragment>
            )}
        </Fragment>
    );
};

export default Producto;